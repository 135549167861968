<template>
    <div class="articleType">
        <!-- 分类管理 -->
        <div class="left">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                
                <el-form-item label="名称" prop="title">
                    <el-input v-model="ruleForm.title" size="small"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="ruleForm.orderNumber" size="small" type="number"></el-input>
                    <span>越小的数字越靠前，只在同级分类上影响。</span>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <template>
                <el-table :data="tableData" border stripe style="width: 100%">
                    <el-table-column prop="id" label="id">
                    </el-table-column>
                    <el-table-column prop="title" label="标题">
                        <template slot-scope="scope">
                            <!-- ({{ scope.row.id }})  -->
                            <span>{{ scope.row.title }} ({{ scope.row.count }})</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="排序">
                    </el-table-column>
                    <el-table-column prop="created" label="创建时间">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="detailAttachmentType(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteAttachmentType(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getAttachmentTypeListAPI, addAttachmentTypeAPI, deleteAttachmentTypeAPI, updateAttachmentTypeAPI, detailAttachmentTypeAPI } from '@/api/attachment/attachmentType'
export default {
    data() {
        return {
            isEdit: false, //是编辑还是新增状态
            // 表单信息
            ruleForm: {
                title: null,
                orderNumber: null
            },
            // 分页
            paging: {
                p: 1,
                ps: 20,
                total: 0,
            },
            rules: {
                title: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            options: [],
            tableData: []
        }
    },
    methods: {
        // 新增、修改附件分类
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {

                    let res = {};
                    //    let info = {title: this.ruleForm.title, orderNumber: this.ruleForm.orderNumber}
                    if (this.isEdit === true) {
                        res = await updateAttachmentTypeAPI(JSON.stringify(this.ruleForm));
                       // console.log(res);
                    } else {
                        res = await addAttachmentTypeAPI(JSON.stringify(this.ruleForm));
                     //   console.log(res);
                    }

                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.ruleForm = {
                            title: null,
                            orderNumber: null
                        },
                        this.isEdit = false;
                        this.getList();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                   // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getList();
            //   console.log(`当前页: ${val}`);
        },
        // 获取列表
        async getList() {
            let info = { pageSize: this.paging.ps, pageNum: this.paging.p }
            const res = await getAttachmentTypeListAPI(JSON.stringify(info));
           // console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.list;
                this.paging.total = res.data.total;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 删除附件分类
        deleteAttachmentType(val) {
            this.$confirm('确认删除该分类?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [val]
                const res = await deleteAttachmentTypeAPI(JSON.stringify(arr));
               // console.log(res);
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.getList();
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败！' + res.msg,
                        type: 'error'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });


        },
        // 获取附件分类详情
        async detailAttachmentType(val) {
            this.isEdit = true;
            const res = await detailAttachmentTypeAPI({ id: val });
           // console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        }

    },
    created() {
        this.getList();
    }
}
</script>

<style lang="scss" scoped>
.articleType {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .left {
        width: 40%;
        padding-right: 40px;
    }

    .right {
        width: 59%;
        // height: 100%;
        min-height: 500px;
        // background: wheat;
    }
}
</style>