import request from '@/utils/request'

import store from '@/store'
// store.dispatch('getAPI');
var info = JSON.parse(sessionStorage.getItem('APIinfo'));

var info = store.state.APIinfo;
// 获取附件分类列表
export function getAttachmentTypeListAPI(data) {
    return request({
        url: `/attachment/attachmentCategoryList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 添加附件分类
export function addAttachmentTypeAPI(data) {
    return request({
        url: `/attachment/addAttachmentCategory?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 修改附件分类
export function updateAttachmentTypeAPI(data) {
    return request({
        url: `/attachment/updateAttachmentCategory?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 删除附件分类
export function deleteAttachmentTypeAPI(data) {
    return request({
        url: `/attachment/deleteAttachmentCategory?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 获取附件分类详情
export function detailAttachmentTypeAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/attachment/findAttachmentCategoryById',
    method: 'get',
    params: newParam
  });
}